import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  ArticleExcerpt,
  ArticlePublishedDate,
  ArticleTitle,
  ArticleHero,
} from "../components/blog-elements"

const BlogPostTileContainer = styled.div`
  display: flex;
  overflow: hidden;
  min-height: 100%;
  max-height: 100%;

  & a {
    color: inherit;
  }

  @media (max-width: 960px) {
    flex-direction: column !important;
  }
`

const ContentContainer = styled.div`
  margin-top: 28px;
  padding: 0px 4px;
`

const ContentContainerLarge = styled.div`
  padding: 4px 64px;

  @media (max-width: 960px) {
    margin-top: 28px;
    padding: 0px 4px;
  }
`

const BlogPostTile = props => {
  const { data, layout = "column", style = {} } = props
  const { fields, metadata, excerpt } = data
  const { title, cover } = metadata
  const { slug: path, date } = fields
  const rowLayout = layout === "row"
  const TitleContentClass = rowLayout ? ContentContainerLarge : ContentContainer
  const imageContainerStyle = { minWidth: rowLayout ? "50%" : "100%" }
  return (
    <Link className="blog-post__tile-container" to={path} style={style}>
      <BlogPostTileContainer
        className="blog-post__tile"
        style={{ flexDirection: layout }}
      >
        <ArticleHero
          className="blog-post__hero"
          style={imageContainerStyle}
          image={cover}
        />
        <TitleContentClass>
          <ArticleTitle className="blog-post__title">{title}</ArticleTitle>
          <ArticlePublishedDate className="blog-post__date" date={date} />
          <ArticleExcerpt className="blog-post__excerpt">
            {excerpt}
          </ArticleExcerpt>
        </TitleContentClass>
      </BlogPostTileContainer>
    </Link>
  )
}

export default BlogPostTile
