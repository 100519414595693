import React from "react"
import styled from "styled-components"
import PodcastTile from "../components/podcast-tile"
import { intersperse } from "../helpers/functions"

import "./blog.css"

const PodcastGrid = styled.div`
  display: flex;
  flex-direction: column;
`

const PodcastsGrid = ({ posts, style }) => {
  return (
    <PodcastGrid style={style}>
      {[
        ...intersperse(
          posts.map(({ post }) => <PodcastTile key={post.id} data={post} />),
          <hr />
        ),
      ]}
    </PodcastGrid>
  )
}

export default PodcastsGrid
