import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Page from "../components/page"
import NewsletterForm from "../components/newsletter-form"
import BlogPostsGrid from "../components/blog-posts-grid"
import PodcastsGrid from "../components/podcasts-grid"
import PodcastBanner from "../components/podcast-banner"
import { SectionLarge } from "../components/elements"

const HomePage = () => (
  <StaticQuery
    query={graphql`
      query HomePageQuery {
        blogPostsData: allMarkdownRemark(
          filter: { fields: { sourceInstanceName: { eq: "blog" } } }
          sort: { order: DESC, fields: [fields___date] }
          limit: 3
        ) {
          ...BlogPosts
        }
        podcastsData: allMarkdownRemark(
          filter: { fields: { sourceInstanceName: { eq: "podcast" } } }
          sort: { order: DESC, fields: [fields___date] }
          limit: 2
        ) {
          ...Podcasts
        }
      }
    `}
    render={data => <IndexPageContent {...data} />}
  />
)

const PodcastsHomeGrid = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 960px) {
    grid-template-columns: auto;
  }
`

const LatestPodcastsSection = ({ podcasts }) => {
  if (!podcasts || podcasts.length < 1) {
    return null
  }
  return (
    <SectionLarge>
      <PodcastsHomeGrid>
        <PodcastBanner />
        <div>
          <h2 style={{ marginTop: "-10px" }}>Gli ultimi episodi dal Podcast</h2>
          <PodcastsGrid posts={podcasts} />
          <Link to="/podcast" style={{ display: "block", marginTop: "64px" }}>
            <strong>Vai alla lista completa degli episodi</strong>
          </Link>
        </div>
      </PodcastsHomeGrid>
    </SectionLarge>
  )
}

const LatestBlogPostsSection = ({ posts }) => {
  if (!posts || posts.length < 1) {
    return null
  }
  return (
    <SectionLarge>
      <h2>Gli ultimi articoli dal blog</h2>
      <BlogPostsGrid posts={posts} featureLatest={false} />
    </SectionLarge>
  )
}

const IndexPageContent = props => {
  const { blogPostsData, podcastsData } = props
  const { blogPosts } = blogPostsData
  const { podcasts } = podcastsData
  return (
    <Page>
      <SectionLarge className="flex-container-column">
        <div className="text-center" style={{ maxWidth: "960px" }}>
          <h1 className="heading large">Investi nel tuo futuro</h1>
          <h2 className="subheading" style={{ maxWidth: "660px" }}>
            Segui il nostro percorso verso la libertà finanziaria e impara con
            noi ad investire come i più grandi maestri di tutti i tempi!
          </h2>
        </div>
      </SectionLarge>
      <SectionLarge>
        <NewsletterForm />
      </SectionLarge>
      <LatestPodcastsSection podcasts={podcasts} />
      {podcasts.length > 0 && blogPosts.length > 0 && (
        <hr style={{ marginTop: "0" }} />
      )}
      <LatestBlogPostsSection posts={blogPosts} />
    </Page>
  )
}

export default HomePage
