import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  ArticleExcerpt,
  ArticlePublishedDate,
  ArticleTitle,
  ArticleHero,
} from "../components/blog-elements"
import { TwoColumnsGrid } from "./containers"

const PodcastTileContainer = styled(TwoColumnsGrid)`
  align-items: start;
  justify-items: start;
  grid-template-columns: unset;
  grid-template-areas: "cover info info" "cover description description";
  grid-row-gap: 0;

  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;

  & a {
    color: inherit;
  }

  & .podcast__cover {
    margin-top: 4px;
    min-width: 150px;
    min-height: 150px;
    box-shadow: 1px 2px 4px 0px rgba(104, 95, 116, 0.3);
  }

  & .podcast__title {
    margin-top: -4px;
  }

  @media (max-width: 960px) {
    grid-template-columns: 75px auto;
    grid-template-areas: "cover info" "description description";

    & .podcast__cover {
      min-width: 75px;
      min-height: 75px;
    }

    & .podcast__title {
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* number of lines to show */
    }
  }
`

const ContentContainer = styled.div``

const PodcastTile = props => {
  const { data, style = {} } = props
  const { fields, metadata, excerpt } = data
  const { title, cover } = metadata
  const { slug: path, date } = fields
  return (
    <Link to={path} style={{ ...style, height: "180px", minHeight: "180px" }}>
      <PodcastTileContainer>
        <ArticleHero
          className="podcast__cover"
          image={cover}
          style={{ gridArea: "cover" }}
        />
        <ContentContainer style={{ gridArea: "info" }}>
          <ArticleTitle className="podcast__title">{title}</ArticleTitle>
          <ArticlePublishedDate className="podcast__date" date={date} />
        </ContentContainer>
        <ContentContainer style={{ gridArea: "description" }}>
          <ArticleExcerpt className="podcast__excerpt">
            {excerpt}
          </ArticleExcerpt>
        </ContentContainer>
      </PodcastTileContainer>
    </Link>
  )
}

export default PodcastTile
