import React from "react"
import styled from "styled-components"

import Image from "./image"
import PodcastLinks from "./podcast-links"
import { OneColumnGrid, TwoColumnsGrid } from "./containers"

const PodcastBannerContainer = styled.div`
  padding: 32px;
  background-color: #fafafa;
`

const PodcastCoverContainer = styled.div`
  width: 200px;
  height: 200px;
  box-shadow: 1px 2px 4px 0px rgba(104, 95, 116, 0.3);
`

const BannerTextContainer = styled.div`
  @media (max-width: 960px) {
    text-align: center;

    & * {
      justify-content: center !important;
    }
  }
`

const BannerTitleSmall = styled.h3`
  line-height: 1;
`

const BannerTitleLarge = styled.h1`
  margin-bottom: 18px;
  line-height: 1;
`

const AutoTwoColumnsGrid = styled(TwoColumnsGrid)`
  grid-template-columns: auto auto;
  grid-column-gap: 100px;
  justify-items: center;
`

const PodcastBanner = props => {
  const { layout = "vertical" } = props
  const isHorizontal = layout === "horizontal"
  const containerStyle = isHorizontal
    ? { width: "100%", padding: "100px 24px" }
    : {}
  const coverContainerStyle = isHorizontal
    ? { margin: "0" }
    : { margin: "0 auto" }
  const textContainerStyle = isHorizontal
    ? { marginTop: "-2px" }
    : { margin: "64px auto" }
  const linksLayout = isHorizontal ? "left" : "center"
  const BannerTitleClass = isHorizontal ? BannerTitleLarge : BannerTitleSmall
  const BannerContainer = isHorizontal ? AutoTwoColumnsGrid : OneColumnGrid
  return (
    <PodcastBannerContainer style={containerStyle}>
      <BannerContainer
        style={{
          maxWidth: "860px",
          margin: "0 auto",
        }}
      >
        <PodcastCoverContainer style={coverContainerStyle}>
          <Image source="podcast-cover.jpg" alt="Podcast cover" />
        </PodcastCoverContainer>
        <BannerTextContainer style={textContainerStyle}>
          <BannerTitleClass>Ascolta il nostro podcast</BannerTitleClass>
          <p style={{ marginBottom: isHorizontal ? "32px" : "64px" }}>
            Un podcast dove ogni settimana si parla di soldi, come investirli, e
            come raggiungere l'indipendenza finanziaria.
          </p>
          <PodcastLinks layout={linksLayout} />
        </BannerTextContainer>
      </BannerContainer>
    </PodcastBannerContainer>
  )
}

export default PodcastBanner
