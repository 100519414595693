import React from "react"
import styled from "styled-components"
import BlogPostTile from "../components/blog-post-tile"
import { ThreeColumnsGrid } from "./containers"

import "./blog.css"

const PostsGrid = styled(ThreeColumnsGrid)`
  grid-column-gap: 24px;
  grid-row-gap: 48px;

  @media (max-width: 960px) {
    grid-template-rows: auto !important;

    & .blog-post__tile-container {
      grid-column: unset !important;
    }
  }
`

const BlogPostsGrid = ({ posts, featureLatest = true }) => {
  const gridStyle = { gridTemplateRows: featureLatest ? "350px auto" : "auto" }
  return (
    <PostsGrid style={gridStyle}>
      {posts.map((post, index) => {
        const shouldFeaturePost = featureLatest && index === 0
        const style = shouldFeaturePost ? { gridColumn: "1 / span 3" } : {}
        const layout = shouldFeaturePost ? "row" : "column"
        return (
          <BlogPostTile
            key={post.id}
            data={post}
            style={style}
            layout={layout}
          />
        )
      })}
    </PostsGrid>
  )
}

export default BlogPostsGrid
